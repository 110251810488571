<template>
    <footer>
      <div class="px-6">
        <v-container class="px-1" >
            <div class="d-flex justify-end align-center">
                <LanguageSwitcher />
            </div>
        </v-container>
      </div>
    </footer>
</template>

<script>
// import Api from "@/apis/Api";
// import moment from "moment";

// import SearchInput from "../../components/Inputs/SearchInput.vue";
// import UserListDropdownItem from "./UserListDropdownItem.vue";
import LanguageSwitcher from '../LanguageSwitcher.vue';

export default {
  components: {
    LanguageSwitcher,
  },
}
</script>

<style scoped lang="scss">
.footer::v-deep {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
.v-menu {
  display: block !important;
}

.v-list.styled-scroll-small {
  .v-list-item::v-deep {
    height: 56px !important;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    .gray--text {
      transition: color 0.2s ease-in-out;
    }
    &:before {
      display: block !important;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 56px;
      opacity: 0.05;
      background-color: transparent;
      transition: background-color 0.2s ease-in-out;
    }
    &:hover {
      .gray--text {
        color: var(--v-primary-base) !important;
      }
      &:before {
        background-color: var(--v-primary-base) !important;
      }
    }
    &.v-list-item--active.v-list-item--link.theme--light:hover {
      color: var(--v-primary-base) !important;
      .check .v-icon svg {
        color: var(--v-primary-base) !important;
      }
    }
  }
}



// input {
//   outline: none !important;
//   width: 100%;
//   height: 44px;
// }
</style>